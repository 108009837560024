/* Playwrite_GB_S Variable Font (Italic) */
@font-face {
  font-family: 'PlaywriteGBS';
  src: url('./components/assets/fonts/Playwrite_GB_S/PlaywriteGBS-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust if you have a specific range */
  font-style: italic;
}

/* Playwrite_GB_S Variable Font (Normal) */
@font-face {
  font-family: 'PlaywriteGBS';
  src: url('./components/assets/fonts/Playwrite_GB_S/PlaywriteGBS-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust if you have a specific range */
  font-style: normal;
}

/* SourGummy Variable Font */
@font-face {
  font-family: 'SourGummy';
  src: url('./components/assets/fonts/Sour_Gummy/SourGummy-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900; /* Adjust if applicable */
  font-style: normal;
}
